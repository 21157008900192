import Navbar from './Navbar';
import Footer from './Footer';

export default function GenAI(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>GenAI Dynamics</h1>
                </div>
                <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Leverage the power of generative AI with our specialized tooling implementations. We assist in deploying advanced AI models, integrating them seamlessly with your existing systems, and customizing them to fit your specific needs. Our services enhance content creation, automate processes, and drive innovation across various business functions, ensuring ongoing support and optimization for maximum efficiency.</h5>
                <div className='tsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> Generative Artificial Intelligence Dynamics (GAID)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Intelligent Automation for Cloud Platforms</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Leverage advanced generative AI to automate complex workflows and processes within cloud-based tools such as ServiceNow and JIRA. Enhance efficiency by reducing manual intervention and accelerating task completion through intelligent, AI-driven automation.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Advanced Data Synthesis and Insights</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Utilize generative AI to analyze and synthesize vast amounts of data across cloud-based systems. Gain actionable insights and predictive analytics to drive strategic decision-making and optimize operational performance.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Dynamic Content Generation</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Harness AI to create dynamic, contextually relevant content within platforms like ServiceNow and JIRA. From generating automated responses to crafting detailed reports, our solutions enhance user interactions and streamline content creation.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Seamless Integration and Adaptation</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Integrate generative AI seamlessly with your existing cloud-based tools, ensuring a smooth and adaptive implementation. Our solutions are designed to enhance the functionality of platforms like JIRA and ServiceNow without disrupting existing workflows.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Scalable and Secure AI Solutions</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}} align="justify">Deploy scalable generative AI solutions that grow with your business while maintaining robust security protocols. Ensure that AI integrations comply with industry standards and protect sensitive data within cloud environments.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 tsm--key--style'>
                    <div className='tsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities of Generative Artificial Intelligence</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"18px"}}>Advanced Data Synthesis</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Scalable Performance</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Seamless Integration</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Enhanced Creativity and Innovation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Dynamic Personalization</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Automated Content Creation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}