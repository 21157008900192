import React, { useState } from 'react';
import dotsitologo from '../images/MicrosoftTeams-image (5).png';
import { NavLink } from 'react-router-dom';
import up from "../images/upload.png";
import down from "../images/arrow-down-sign-to-navigate.png";
import whoweare from "../images/hands-photo-1024x731.jpg";
import ESM from '../images/itsm.png';
import DEV from '../images/devops.png';
import ISS from '../images/security.png';
import GRC from '../images/GRC.png';
import ITAM from '../images/itam.png';
import QAT from '../images/test.png';
import rightArrow from '../images/right-arrow.png'
import rightArrowColor from '../images/Vector.png';
import life from '../images/life.png';
import platform from '../images/Nowplatfrom.jpg'
import leader from '../images/leader.png';
import learn from '../images/learn.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function Navbar() {
    const [toggle,setToggle] = useState(false)
    const [toggle1,setToggle1] = useState(false)

    function handleOpen(){
        setToggle(true)
    }
    function handleOut(){
        setToggle(false)
    }

    function handleOpen1(){
        setToggle1(true)
    }
    function handleOut1(){
        setToggle1(false)
    }


    return (
        <div className='col-sm-12 header sticky-top'>
            <div className='row'>
                <nav className='navbar col-sm-12 navbar-expand-lg'>
                    <a className='navbar-brand col-sm-4 text-center'>
                        <a href='/'><img src={dotsitologo} className='navbar--image--mobile' width="350px" alt='nothing'></img></a>
                    </a>
                    <button className="p-1 toggle--button--mobile navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="col-sm-8 navbar-collapse collapse p-3 mlnav--mobile" id="navbar">
                        <ul className='navbar-nav mx-auto navbar--nav'>
                            <li className='nav-item navbar--web'>
                                <div className='dropdown' onMouseOver={handleOpen} onMouseOut={handleOut}>
                                    <li className='nav--active'>
                                        <NavLink to='/about/whoweare' className="nav-link nav--color">
                                            About
                                        </NavLink>
                                    </li>
                                    { toggle ?
                                    <div className=''>
                                        <div className="dropdown--alignment">   
                                            <img  src={down} alt='up' height={10} />
                                        </div>
                                        {/* <div className="col-sm-8 mt-3 dropdown-menu"> */}
                                        <div className="dropdown-menu col-sm-8 mt-3">
                                            <div class="row">
                                                <div className='col-sm-4 '>
                                                    <li className='nav--active'>
                                                        <NavLink to="/about/whoweare" style={{height: 100}}>
                                                            <div className='col-sm-12 bg'>
                                                                <div className='row'>
                                                                    <div className='col-sm-4 navbar--content p-4'>
                                                                        <img src={whoweare} height={80} className='navbar--image' />
                                                                    </div>
                                                                    <div className='col-sm-8'>
                                                                        <div className='col-sm-9 dropdown--content'>
                                                                            <p>Who We Are</p>
                                                                            <ArrowCircleRightIcon className="wwr--icon" style={{fontSize: "35px"}}></ArrowCircleRightIcon> 
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <li className='nav--active'>
                                                        <NavLink to="/about/ourleadership" style={{height: 100}}>
                                                            <div className='col-sm-12 bg'>
                                                                <div className='row'>
                                                                    <div className='col-sm-4 navbar--content p-4'>
                                                                        <img src={leader} height={85} width={118} className='navbar--image' />
                                                                    </div>
                                                                    <div className='col-sm-8'>
                                                                        <div className='col-sm-9 dropdown--content'>
                                                                            <p>Our Leadership</p>
                                                                            <ArrowCircleRightIcon className="wwr--icon" style={{fontSize: "35px"}}></ArrowCircleRightIcon> 
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <li className='nav--active'>
                                                        <NavLink to="/about/life@dotsito" style={{height: 100}}>
                                                            <div className='col-sm-12 bg'>
                                                                <div className='row'>
                                                                    <div className='col-sm-4 navbar--content p-4'>
                                                                        <img src={life} height={85} width={118} className='navbar--image' />
                                                                    </div>
                                                                    <div className='col-sm-8 '>
                                                                        <div className='col-sm-9 dropdown--content'>          
                                                                            <p>Life @ Dotsito</p>
                                                                            <ArrowCircleRightIcon className="wwr--icon" style={{fontSize: "35px"}}></ArrowCircleRightIcon> 
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <li className='nav--active'>
                                                        <NavLink to="/about/learn" style={{height: 100}}>
                                                            <div className='col-sm-12 bg'>
                                                                <div className='row'>
                                                                    <div className='col-sm-4 navbar--content p-4'>
                                                                        <img src={learn} height={85} width={118} className='navbar--image' />
                                                                    </div>
                                                                    <div className='col-sm-8 '>
                                                                        <div className='col-sm-9 dropdown--content'>          
                                                                            <p>Learning</p>
                                                                            <ArrowCircleRightIcon className="l--icon" style={{fontSize: "35px"}}></ArrowCircleRightIcon> 
                                                                        </div>
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                </div>
                                            </div >
                                        </div>
                                    </div>
                                      : 
                                      <div className="dropdown--alignment">   
                                            <img  src={up} height={10}/>
                                        </div>
                                      }
                                    
                                </div>
                            </li>
                            <div>
                                <li className='nav-item nav--active'>
                                    <div className='navbar--mobile'>
                                        <div className="dropdown nav-item mt-3">
                                            <a className="nav--align--mobile dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                About
                                            </a>
                                            <div class="dropdown-menu nav--link--align--mobile" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="/about/whoweare">Who We Are</a>
                                                <a class="dropdown-item" href="/about/ourleadership">Our Leadership</a>
                                                <a class="dropdown-item" href="/about/life@dotsito">Life @ Dotsito</a>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                            </div>
                            <li className='nav-item navbar--web'>
                                <div className='dropdown' onMouseOver={handleOpen1} onMouseOut={handleOut1}>
                                    <li className='nav--active'>
                                        <NavLink to='/solution/esm' className="nav-link nav--color">
                                            Solutions
                                        </NavLink>
                                    </li>
                                    { toggle1 ?
                                    <div>
                                        <div className="dropdown--alignment">   
                                            <img  src={down} alt='up' height={10} />
                                        </div>
                                        <div className="col-sm-8 mt-3 dropdown-menu-solution">
                                            <div class="row">
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/esm" style={{height: 100}}>
                                                    <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={ESM} height={80} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>Enterprise Service Management</p>
                                                                        <ArrowCircleRightIcon className="is--icon" style={{fontSize: "35px", top: '13%'}}></ArrowCircleRightIcon>                                   
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/Devops" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={DEV} height={85} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>Devops and Infra Automation</p>
                                                                        <ArrowCircleRightIcon className="is--icon" style={{fontSize: "35px", top: '13%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/ITSecurity" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={ISS} height={85} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                            <p>IT Security Services</p>
                                                                            <ArrowCircleRightIcon className="is--icon" style={{fontSize: "35px", top: '13%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/GRC" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={GRC} height={85} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>Governance, Risk, and Compliance(GRC)</p>
                                                                        <ArrowCircleRightIcon className="qa--icon" style={{fontSize: "35px", top: '39%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/itam" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={ITAM} height={85} width={118} className='navbar--image'/>
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>IT Asset Management</p>
                                                                        <ArrowCircleRightIcon className="qa--icon" style={{fontSize: "35px", top: '39%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/QA&Testing" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={QAT} height={85} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>QA and Testing</p>
                                                                        <ArrowCircleRightIcon className="qa--icon" style={{fontSize: "35px", top: '39%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/sms" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={platform} height={85} width={118} className='navbar--image' />
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                        <p>ServiceNow Managed Services</p>
                                                                        <ArrowCircleRightIcon className="ss--icon" style={{fontSize: "35px",top: '64%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/JIRA" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={'https://dotsito.s3.ap-south-1.amazonaws.com/Dotsito-webpage/jiraservice.webp'} height={85} width={118} className='navbar--image'/>
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                            <p>Jira implementation Support</p>
                                                                            <ArrowCircleRightIcon className="jira--icon" style={{fontSize: "35px",top: '64%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
        
                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/confluence" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={'https://dotsito.s3.ap-south-1.amazonaws.com/Dotsito-webpage/Confluence+Implementation+support.jpg'} height={85} width={118} className='navbar--image'/>
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                            <p>Confluence Integration and Support</p>
                                                                            <ArrowCircleRightIcon className="jira--icon" style={{fontSize: "35px",top: '64%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/GenAI" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={'https://dotsito.s3.ap-south-1.amazonaws.com/Dotsito-webpage/GenAI.webp'} height={85} width={118} className='navbar--image'/>
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                            <p>GenAI Dynamics</p>
                                                                            <ArrowCircleRightIcon className="jira--icon" style={{fontSize: "35px",top: '88%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                <div className='col-sm-4'>
                                                    <NavLink to="/solution/objectdetection" style={{height: 100}}>
                                                        <div className='col-sm-12 bg'>
                                                            <div className='row'>
                                                                <div className='col-sm-4 navbar--content p-4'>
                                                                    <img src={'https://dotsito.s3.ap-south-1.amazonaws.com/Dotsito-webpage/objectdetection.png'} height={85} width={118} className='navbar--image'/>
                                                                </div>
                                                                <div className='col-sm-8 '>
                                                                    <div className='col-sm-9 dropdown--content'>
                                                                            <p>Next-Gen Object Detection and Categorization Suite</p>
                                                                            <ArrowCircleRightIcon className="jira--icon" style={{fontSize: "35px",top: '88%'}}></ArrowCircleRightIcon>
                                                                    </div>
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                
                                            </div >
                                        </div> 
                                    </div>
                                      : 
                                      <div className="dropdown--alignment">   
                                            <img  src={up} height={10}/>
                                        </div>
                                      }
                                    
                                </div>
                            </li>
                            <div>
                                <li className='nav-item nav--active'>
                                    <div className='navbar--mobile'>
                                        <div class="dropdown nav-item mt-3`">
                                            <a class="dropdown-toggle nav--align--mobile"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Solutions
                                            </a>
                                            <div class="dropdown-menu nav--link--align--mobile" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item" href="/solution/esm">Enterprise Service Management</a>
                                                <a class="dropdown-item" href="/solution/Devops">Devops and Infra Automation</a>
                                                <a class="dropdown-item" href="/solution/ITSecurity">IT Security Services</a>
                                                <a class="dropdown-item" href="/solution/GRC">Governance, Risk, and Compliance(GRC)</a>
                                                <a class="dropdown-item" href="/solution/itam">IT Asset Management</a>
                                                <a class="dropdown-item" href="/solution/QA&Testing">QA and Testing</a>
                                                <a class="dropdown-item" href="/solution/sms">ServiceNow Managed Services</a>
                                                <a class="dropdown-item" href="/solution/JIRA">Jira implementation Support</a>
                                                <a class="dropdown-item" href="/solution/GenAI">GenAI Dynamics</a>
                                                <a class="dropdown-item" href="/solution/confluence">Confluence Integration and Support</a>
                                                <a class="dropdown-item" href="/solution/objectdetection">Next-Gen Object Detection and Categorization Suite</a>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className='mt-2'></div>
                                </li>
                            </div>
                            <li className='nav-item nav--active'>
                                <NavLink className='nav-link nav--hover' to='/services'>Services</NavLink>
                            </li>
                            <li className='nav-item nav--active'>
                                <NavLink className='nav-link nav--hover' to='/contact'>Contact</NavLink>
                            </li>
                            <li className='nav-item nav--active'>
                                <NavLink className='nav-link nav--hover' to='/careers'>Careers</NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}