import Navbar from './Navbar';
import Footer from './Footer';

export default function Confluence(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>Confluence Integration and Support</h1>
                </div>
                <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Transform your team's workflow with our expert Confluence integration and support. We enhance document management, knowledge sharing, and project collaboration by seamlessly integrating Confluence with your existing systems. Benefit from tailored setup, continuous support, and improved productivity through seamless information exchange across your organization.</h5>
                <div className='tsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> Confluence Integration and Support (CIS)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Enhanced Document Collaboration</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Streamline team collaboration with advanced features that facilitate real-time editing, commenting, and version control in Confluence. Improve productivity and ensure seamless communication within your team.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Customized Knowledge Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Optimize your knowledge base by customizing Confluence to organize and categorize information effectively. Tailor spaces, pages, and templates to suit your organizational needs and enhance information retrieval.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Integrated Project Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Integrate Confluence with project management tools to create a unified workspace. Synchronize project updates, track progress, and manage tasks directly within Confluence for a cohesive project management experience.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Automated Reporting and Analytics</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Leverage built-in reporting tools to generate automated reports and analytics in Confluence. Monitor project metrics, track team performance, and gain valuable insights with customizable dashboards and reports.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Scalable and Secure Collaboration</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>Implement Confluence solutions that scale with your organization while adhering to stringent security protocols. Ensure robust access controls, data protection, and compliance with industry standards to safeguard your information.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 tsm--key--style'>
                    <div className='tsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Confluence Integration and Support</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"18px"}}>Customizable Workspace Design</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Seamless Tool Integration</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Advanced Permissions and Access Control</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Automated Workflow Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Rich Content Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Scalable Performance Optimization</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}