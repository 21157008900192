import Navbar from './Navbar';
import Footer from './Footer';

export default function Objectdetection(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>Next-Gen Object Detection and Categorization Suite
                    </h1>
                </div>
                <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Enhance operational intelligence with our cutting-edge machine learning solution. Achieve precise, real-time object detection and categorization across multiple camera feeds, seamlessly integrating with your systems to optimize efficiency, inventory management, and quality control.</h5>
                <div className='tsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> Next-Gen Object Detection and Categorization Suite (NGDGS)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>High-Precision Object Identification</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Utilize advanced machine learning techniques to achieve precise detection and classification of objects across multiple camera feeds. Enhance accuracy in identifying and categorizing items based on defined criteria.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Real-Time Analysis and Reporting</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Implement real-time object detection to provide immediate insights and reporting. Monitor object counts and classifications as they occur, allowing for timely decision-making and operational adjustments.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Seamless Integration with Existing Systems</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Integrate machine learning-based object detection seamlessly with your current infrastructure. Ensure smooth connectivity with existing surveillance or monitoring systems for comprehensive data collection and analysis.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Scalable Detection Solutions</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Deploy scalable object detection solutions that adapt to growing needs and expanding camera networks. Easily manage increased data volumes and maintain performance across a diverse range of environments.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Robust Data Processing and Storage</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}} align="justify">Efficiently process and store object detection data with high reliability. Implement solutions that ensure data integrity and security while providing easy access for analysis and reporting.</p>
                    </div>
                </div>
                <Footer />
        </>
    )
}