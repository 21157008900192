import Navbar from './Navbar';
import Footer from './Footer';

export default function JIRA(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>JIRA Implementation Support</h1>
                </div>
                <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Elevate efficiency with our tailored JIRA implementation. From configuration and data migration to process automation and integrations, we streamline workflows and enhance productivity. Benefit from expert onboarding and continuous support to fully leverage your JIRA environment.</h5>
                <div className='tsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> JIRA Implementation Support (JIS)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>JIRA Configuration and Customization</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Tailor JIRA to your specific business needs with our expert configuration and customization services. We design custom workflows, issue types, and field setups to align JIRA with your operational processes, ensuring a seamless fit and enhancing team productivity. Our solutions are crafted to optimize your JIRA environment for maximum efficiency and user satisfaction</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Process Automation</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Streamline your workflows with our advanced process automation solutions. By automating repetitive tasks and complex workflows within JIRA, we help reduce manual effort and minimize errors. This automation enhances efficiency, accelerates task completion, and allows your team to focus on higher-value activities.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Integration with Other tools</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify">Enhance your JIRA experience by integrating it with other essential business tools such as Confluence,MicrosoftTeams, Slack, and ServiceNow. Our integration services create a cohesive work environment, facilitating seamless communication and data flow across platforms. This unified approach improves collaboration, boosts productivity, and leverages data across your toolset.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>JIRA Reporting Dashboard</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}} align="justify"> Unlock actionable insights with our JIRA reporting dashboard solutions. We design and implement customized dashboards that provide real-time visibility into project metrics, performance trends, and key indicators. Our dashboards are tailored to your specific reporting needs, enabling you to make data-driven decisions, track progress efficiently, and enhance overall project management.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Data Migration</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}} align="justify">Seamlessly transition your data to JIRA with our comprehensive data migration services. We handle the secure and accurate transfer of information from legacy systems or other project management tools, ensuring minimal disruption to your operations. Our meticulous approach preserves data integrity and ensures a smooth continuation of your projects within JIRA.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 tsm--key--style'>
                    <div className='tsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of JIRA Implementation Service</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"18px"}}>Bespoke Workflow Engineering</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Configuration Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Expert Data Migration Solutions</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Process Automation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Dynamic Reporting and Analysis</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Scalable Agile Solutions</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}